// @flow

import React, { type Node } from "react";

import { Permissions } from "../../api/permissions";
import { UpdateNoiseMask } from "../../containers";
import { usePermissions } from "../../hooks";
import NotFoundPage from "../404";

type Props = {
    id: string,
};

const NoiseMaskUpdate = ({ id }: Props): Node => {
    const [canManage] = usePermissions([Permissions.NOISEMASK_MANAGE]);

    if (!id) {
        return <NotFoundPage />;
    }

    const [companyId, designId] = id.split("-");

    return (
        <UpdateNoiseMask
            companyId={companyId}
            designId={designId}
            readOnly={!canManage}
        />
    );
};

export default NoiseMaskUpdate;
